<template>
  <div>
    <div style="display: flex">
      <el-container>
        <CommonTree
        ref="tree"
        node-key="id"
          :defaultProps="defaultProps"
          :isShowdig="false"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="data"
          :treeTitle="$t('omit.Topicclassification')"
          :treeExpand="true"
          style="font-size: 13px"
          @getNodeClick="handleNodeClick"
        />
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t('cip.dc.DataDirectory.field.Datadirectory')"
            @head-add="addFn"
          >
          </head-layout>

          <grid-layout
            ref="gridLayout"
            :data-total="total"
            :gridRowBtn="gridRowBtn"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
            @grid-see="glanceDataFn"
            @grid-edit="miaoshuFn"
            @grid-del="deltableList"
          >
            <template slot="catalog_value" slot-scope="{ row }">
              {{ queryData.name }}
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>
    <div>
      <el-drawer
        :with-header="false"
        :direction="direction"
        :visible.sync="drawer"
        size="60%"
      >
        <div style="margin-top: 20px">
          <el-form :model="form" inline="true">
            <el-form-item
              :label="$t('cip.dc.DataDirectory.field.Datadirectory')"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.tableName"
                autocomplete="off"
                disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('cip.dc.DataDirectory.field.explain')"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.tableComment"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('cip.dc.DataDirectory.field.catalogue')"
              :label-width="formLabelWidth"
            >
              <el-input v-model="catalog_name" disabled="true" />
            </el-form-item>
          </el-form>
        </div>
        <div style="padding: 0px 20px">
          <el-tabs v-model="activeName1" @tab-click="handleClick">
            <el-tab-pane
              :label="$t('cip.dc.DataDirectory.field.metadata')"
              name="1"
            >
              <el-table :data="yuanshujuList" border style="width: 100%">
                <el-table-column
                  :label="$t('cip.dc.DataDirectory.field.Columnname')"
                  prop="columnName"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('cip.dc.DataDirectory.field.explain')"
                  prop="columnComment"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('cip.dc.DataDirectory.field.datatype')"
                  prop="dataType"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :direction="direction"
        :visible.sync="drawer1"
        size="60%"
        @closed=closedrawer
      >
        <div style="padding: 0px 20px">
          <head-layout
            :head-btn-options="headBtnOptions2"
            :head-title="$t('omit.queryCriteria')"
            @head-add2="addParms"
            @head-see="searchSee"
          >
          </head-layout>
          <el-table
            :data="seeParms"
            border
            style="width: 100%; margin-top: 10px"
          >
            <el-table-column :label="$t('Token.table.Fieldname')">
              <template slot-scope="scope">

                <el-select
                  filterable
                  clearable
                  v-model="scope.row.fieldName"
                  :placeholder="$t('omit.select')"
                >
                  <el-option
                    v-for="(item, index) in liulantitleCN"
                    :key="index"
                    :label="item.column_name"
                    :value="item.column_name"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column :label="$t('omit.type')">
              <template slot-scope="scope">
                <el-select
                  filterable
                  clearable
                  v-model="scope.row.queryType"
                  :placeholder="$t('omit.select')"
                  @change="changeQueryType($event, scope.row)"
                >
                  <el-option
                    v-for="(item, index) in queryTypeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('cip.dc.dataobject.field.enterValue')"
              width="360"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.queryType && scope.row.queryType !== '-1'">
                  <div v-if="scope.row.queryType == '3'">
                    <el-input
                      clearable
                      v-model="scope.row.startValue"
                      :placeholder="$t('omit.start')"
                      style="width: 50%"
                    ></el-input>
                    <el-input
                      clearable
                      v-model="scope.row.endValue"
                      :placeholder="$t('omit.end')"
                      style="width: 50%"
                    ></el-input>
                  </div>
                  <el-input
                    v-if="scope.row.queryType !== '3'"
                    v-model="scope.row.inputValue"
                    :placeholder="$t('omit.input')"
                  ></el-input>
                </div>
              </template>
            </el-table-column>

            <el-table-column :label="$t('Primordial.tabData.operate')" width="80">
              <template slot-scope="scope">
                <el-button type="text" @click="delParms(scope.row)"
                  >{{ this.$t("cip.cmn.btn.delBtn") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <head-layout :head-title="$t('cip.dc.dataobjDComission.searchres')"> </head-layout>

          <el-table
            :data="liulanList"
            border
            style="width: 100%; margin-top: 10px"
            class="show_table"
          >
            <el-table-column
              v-for="(item, index) in liulantitleCN"
              :key="item.column_name"
              :label="item.column_name"
              :prop="item.column_name"
              width="120"
            >
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :currentPage="currentPage2"
            :page-sizes="[50, 100]"
            :page-size="PageSize2"
            layout="total,sizes, prev, pager, next"
            :total="totalCount2"
            class="paging"
          >
          </el-pagination>
        </div>
      </el-drawer>
      <el-dialog
        :title="$t('cip.cmn.btn.addBtn')"
        :visible.sync="dialogVisible"
        width="50%"
      >
        <div>
          <el-form
            ref="ruleForm"
            :inline="true"
            :model="muluForm"
            :rules="rules"
            label-width="120px"
          >
            <el-form-item
              :label="$t('cip.dc.DataDirectory.field.Datasource')"
              prop="sourceId"
            >
              <el-select
                filterable
                v-model="muluForm.sourceId"
                size="mini"
                @change="sourceChange"
              >
                <el-option
                  v-for="(item, index) in sourceList"
                  :key="index"
                  :label="item.desc"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('cip.dc.DataDirectory.field.Tablename')"
              prop="metadataTableNameList"
            >
              <el-select
                filterable
                v-model="muluForm.metadataTableNameList"
                multiple
                size="mini"
              >
                <el-option
                  v-for="(item, index) in biaoList"
                  :key="index"
                  :label="item.table_name"
                  :value="item.table_name"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="quxiaoFn">{{ $t("cancelText") }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="headSave"
            >{{ $t("submitText") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import CommonTree from "@/views/components/com_tree/index";
import {
  getAiModelList,
  getDtaDirectoryAllByPid,
  getTableColumnList,
  getTableRelationshipById,
  glanceData,
  modelList,
  saveDataCatalog,
  updateTableColumn,
  directoryDel,
} from "@/api/dataAcquisition/DataDirectory";
import { getDataDirectoryAllByPid } from "@/api/dataAcquisition/offline";
import { listAll } from "@/api/dataAcquisition/DataServices";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      seeParms: [
        {
          fieldName: "", //字段名称
          queryType: "", //,
          inputValue: "", //输入值,
          startValue: "", //开始值,
          endValue: "", //结束值
        },
      ],

      currentPage2: 1,
      // 总的数据条数
      totalCount2: 0,
      // 默认每页显示的条数（可修改）
      PageSize2: 20,
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0,
      },
      treeId:"",//当前树id

      queryTypeList: [
      { label: this.$t('cip.dc.offlineDComission.dengyu'), value: "1", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.budengyu'), value: "2", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.qujian'), value: "3", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.dayu'), value: "4", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaoyu'), value: "5", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.dadengyu'), value: "6", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaodengyu'), value: "7", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.baohan'), value: "8", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.bubaohan'), value: "9", type: "1" },
      ],

      searchTitle: "name",
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: this.$t("cip.dc.DataDirectory.field.Dataname"),
            prop: "tableName",
            cell: true,
          },
          {
            label: this.$t("cip.dc.DataDirectory.field.datadescription"),
            prop: "tableComment",
            cell: true,
          },
          {
            label: this.$t("cip.dc.DataDirectory.field.Datadirectory"),
            prop: "catalog_value",
            cell: true,
            slot: true,
          },
          {
            label: this.$t("cip.dc.DataDirectory.field.datatype"),
            prop: "dbTypeName",
            cell: true,
          },
        ],
      },
      gridRowBtn: [
        {
          label: this.$t("cip.dc.DataDirectory.btn.Description"),
          emit: "grid-edit",
          remark: "Description",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.dc.DataDirectory.btn.BrowseData"),
          emit: "grid-see",
          remark: "see",
          type: "text",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "grid-del",
          remark: "see",
          type: "text",
          icon: "",
        },

      ],
      tableLoading: false,

      catalog_name: "111",
      filterText: "",
      drawer1: false,
      liulantitleCN: [],
      liulanList: [],
      liulanList2: [],
      activeName1: "1",
      total: 0,
      queryList: {
        current: 1,
        size: 10,
        tableName: "",
        modelId: "",
      },
      tableData: [],
      activeName: "list",
      queryList1: {
        name: "",
      },
      multipleSelection: [],
      headBtnOptions: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],
      headBtnOptions2: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add2",
          type: "button",
          icon: "",
        },
        {
          label: "查询",
          emit: "head-see",
          type: "button",
          icon: "",
        },
      ],
      rules: {
        metadataTableNameList: [
          {
            required: true,
            message: this.$t("cip.dc.DataDirectory.rules.rules1"),
            trigger: "change",
          },
        ],
        sourceId: [
          {
            required: true,
            message: this.$t("cip.dc.DataDirectory.rules.rules2"),
            trigger: "change",
          },
        ],
      },
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      rowId: "",
      rightData: [],
      rightDetailData: [],
      drawer: false,
      direction: "rtl",
      formLabelWidth: "80px",
      form: {
        tableName: "",
        tableComment: "",
      },
      mlSelect: [],
      yuanshujuList: [],
      shujuxueyuanList: null,
      queryData: null,
      muluForm: {
        bdDataDirectoryId: "",
        metadataTableNameList: [],
        sourceId: "",
      },
      biaoList: [],
      sourceList: [],
      source: {},
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false),
      };
    },
  },
  methods: {
    addParms() {
      this.seeParms.push({
        fieldName: "", //字段名称
        queryType: "", //,
        inputValue: "", //输入值,
        startValue: "", //开始值,
        endValue: "", //结束值
      });
    },

    delParms(row) {
      this.seeParms.forEach((element, index) => {
        if (element == row) {
          this.seeParms.splice(index, 1);
        }
      });
    },
    changeQueryType(e, row) {
      row.inputValue = ""; //输入值,
      row.startValue = ""; //开始值,
      row.endValue = ""; //结束值
    },
    closedrawer(){
      this.seeParms=[
        {
          fieldName: "", //字段名称
          queryType: "", //,
          inputValue: "", //输入值,
          startValue: "", //开始值,
          endValue: "", //结束值
        },
      ]
    },

    // 修改每页显示的条数
    handleSizeChange2(side) {
      // 改变每页显示的条数
      this.PageSize2 = side;
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage2 = 1;

      let parms = {
        id: this.rowId,
        current: this.currentPage2,
        size: side,
        conditionFields: this.seeParms
      };

      this.MseeData(parms);
    },
    // 显示第几页
    handleCurrentChange2(pageNumber) {
      // 改变默认的页数
      this.currentPage2 = pageNumber;
      let parms = {
        id: this.rowId,
        current: this.currentPage2,
        size: this.PageSize2,
        conditionFields: this.seeParms
      };

      this.MseeData(parms);
    },

    init() {
      this.getDtaDirectoryAllByPidFn();
      this.sourceListFn();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    quxiaoFn() {
      this.dialogVisible = false;
    },
    //todo 新增保存
    headSave() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$loading();
          saveDataCatalog(this.muluForm).then((res) => {
            this.$message.success(this.$t("cip.dc.DataDirectory.msg.msg1"));
            this.getAiModelListFn();
            this.$loading().close();
            this.dialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //todo 数据源下拉
    sourceListFn() {
      listAll({}).then((res) => {
        this.sourceList = res.data.data;
      });
    },
    //todo 数据源改变查询数据表
    sourceChange(value) {
      this.muluForm.metadataTableNameList = [];
      let source = this.sourceList.find((item) => item.id == value);
      this.source = source;
      modelList({
        host_id: source.name,
        dbType: source.dbtype,
        id: source.id,
      }).then((res) => {
        this.biaoList = res.data.data;
      });
    },
    handleNodeClick(data) {
      this.catalog_name = data.name;
      console.log(this.form.catalog_id, "this.form.catalog_id");
      this.queryData = data;
      this.queryList.modelId = data.id;
      this.getAiModelListFn(data);
    },
    //todo 右侧列表
    getAiModelListFn() {
      getAiModelList(this.queryList).then((res) => {
        this.tableData = res.data.data.dataList;
        this.total = res.data.data.page.totalSize;
      });
    },
    //todo 左侧树
    getDataDirectoryAllByPidFn() {
      getDataDirectoryAllByPid({}).then((res) => {
        this.data = res.data.data;
        this.$nextTick(()=>{
         let p1 ={}
         if(this.$route.query.pid){
          this.data.forEach(element => {
            if(element.id ==this.$route.query.pid){
              element.children.forEach(element2 => {
                if(element2.id==this.$route.query.id){
                  p1 = element2
                }

              });
            }
          });
         }else{
          p1 = this.data[0].children[0]
         }
          this.$refs.tree.$refs.commonTree.setCurrentKey(p1.id)
          this.catalog_name = p1.name;
      this.queryData = p1;
      this.queryList.modelId = p1.id;
      this.getAiModelListFn();
          })
      });
    },
    addFn() {
      if (!this.queryData) {
        this.$message.error(this.$t("cip.dc.DataDirectory.msg.msg2"));
        return;
      }
      this.dialogVisible = true;
      this.muluForm = {
        bdDataDirectoryId: this.queryData.id,
        metadataTableNameList: [],
        sourceId: "",
      };
    },
    //todo 描述按钮
    miaoshuFn(row) {
      this.drawer = true;
      row.dbType = row.dbtype_id;
      this.getTableColumnListFn(row);
      // this.getTableRelationshipByIdFn(row);
    },
    //todo 描述中元数据
    getTableColumnListFn(row) {
      getTableColumnList({
        tableId: row.id,
      }).then((res) => {
        console.log(res, "元数据");
        let obj = res.data.data;
        this.yuanshujuList = obj.metadataColumnList;
        this.form = obj;
      });
    },
    glanceDataFn(row) {
      this.drawer1 = true;
      this.rowId = row.id;
      this.PageSize2 = 50;
      this.currentPage2 = 1;
      let parms = {
        id: this.rowId,
        current: this.currentPage2,
        size: this.PageSize2,
        conditionFields: this.seeParms,
      };
      this.MseeData(parms);
    },

    searchSee() {
      let parms = {
        id: this.rowId,
        current: 1,
        size: this.PageSize2,
        conditionFields: this.seeParms,
      };
      this.MseeData(parms);
    },

    MseeData(params) {
      glanceData({
        id: params.id,
        current: params.current,
        size: params.size,
        conditionFields: params.conditionFields,
      }).then((res) => {
        let obj = res.data.data;
        this.liulantitleCN = obj.titleCN;
        this.liulanList = obj.list;
        this.totalCount2 = obj.total;
      });
    },

    //todo 描述中数据血源
    getTableRelationshipByIdFn(params) {
      getTableRelationshipById(params).then((res) => {
        this.shujuxueyuanList = res;
      });
    },
    //todo 描述提交
    updateTableColumnFn() {
      let params = this.form;
      params.lineForm = this.yuanshujuList;
      updateTableColumn(params).then((res) => {
        this.drawer = false;
        this.$message.success(this.$t("cip.dc.DataDirectory.msg.msg3"));
      });
    },
    //todo 目录下拉
    getDtaDirectoryAllByPidFn() {
      getDtaDirectoryAllByPid({}).then((res) => {
        this.mlSelect = res.data.data;
      });
    },
    searchFn() {},
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(val) {
      this.queryList.size = val.pageSize;
      this.getAiModelListFn();
      this.page.pageSize = val.pageSize;
    },
    handleCurrentChange(val) {
      this.queryList.current = val.currentPage;
      this.page.currentPage = val.currentPage;
      this.getAiModelListFn();
    },


    deltableList(row){

      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          console.log("8888888");
          directoryDel({bdDataDirectoryId:this.queryList.modelId,metadataTableId:row.id}).then(res=>{
            this.getAiModelListFn();
            this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });

     })
        }).catch(() => {
      })



    }
  },
  created() {
    this.init();
    this.getDataDirectoryAllByPidFn();
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.el-button + .el-button {
  margin-left: 0px;
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 218px) !important;
  max-height: calc(100vh - 218px) !important;
}
.paging {
  float: right;
  margin-top: 10px;
}
.show_table {
  position: relative;
  width: 100%;
  height: 580px; //高度加到这里
  overflow: auto;
}
</style>
